dmx.slideshow = {};
dmx.slideshow.transitions = {};

dmx.slideshow.Transition = function(slideshow, options) {
    this.slideshow = slideshow;
    this.options = Object.assign({
        requires3d: false,
        setup: dmx.noop,
        createTile: dmx.noop,
        execute: this.finished.bind(this),
        after: dmx.noop
    }, options);
};

dmx.slideshow.Transition.prototype = {
    constructor: dmx.slideshow.Transition,

    hasFinished: false,

    run: function(index) {
        this.index = index;
        this.prevSlide = this.slideshow.slides[this.slideshow.data.index];
        this.nextSlide = this.slideshow.slides[index];
        this.width = this.slideshow.slidesContainer.offsetWidth;
        this.height = this.slideshow.slidesContainer.offsetHeight;
        // run setup
        this.options.setup.call(this);
        // force redraw (bugfix for firefox)
        this.slideshow.$node.offsetWidth;
        // execute on next cycle
        requestAnimationFrame(this.options.execute.bind(this));
    },

    finished: function() {
        if (this.hasFinished) return;
        this.hasFinished = true;
        this.options.after.call(this);
        this.slideshow.transitionEnd();
        this.slideshow.slidesContainer.style.removeProperty('display');
        this.slideshow.effectsContainer.style.removeProperty('overflow');
        this.slideshow.effectsContainer.style.removeProperty('perspective');
        this.slideshow.effectsContainer.style.removeProperty('perspective-origin');
        this.slideshow.effectsContainer.style.removeProperty('transform-style');
        this.slideshow.effectsContainer.innerHTML = '';
    },

    random: function() {
        return arguments[Math.floor(Math.random() * arguments.length)];
    },

    createTiles: function(cols, rows) {
        var colWidth = Math.floor(this.width / cols);
        var colRemainder = this.width - (cols * colWidth);
        var colAddPerLoop = Math.ceil(colRemainder / cols);

        if (rows == 'auto') {
            rows = Math.round(this.height / colWidth);
        }

        var rowHeight = Math.floor(this.height / rows);
        var rowRemainder = this.height - (rows * rowHeight);
        var rowAddPerLoop = Math.ceil(rowRemainder / rows);

        var offsetLeft = 0;

        var fragment = document.createDocumentFragment();

        for (var x = 0; x < cols; x++) {
            var width = colWidth;
            var remainder = rowRemainder;
            var offsetTop = 0;

            if (colRemainder > 0) {
                var add = colRemainder > colAddPerLoop ? colAddPerLoop : colRemainder;
                width += add;
                colRemainder -= add;
            }

            for (var y = 0; y < rows; y++) {
                var height = rowHeight;

                if (remainder > 0) {
                    var add = remainder > rowAddPerLoop ? rowAddPerLoop : remainder;
                    height += add;
                    remainder -= add;
                }

                var tile = document.createElement('div');
                tile.style.setProperty('position', 'absolute');
                tile.style.setProperty('width', width + 'px');
                tile.style.setProperty('height', height + 'px');
                tile.style.setProperty('left', offsetLeft + 'px');
                tile.style.setProperty('top', offsetTop + 'px');

                this.options.createTile.call(this, tile, x, y, width, height, offsetLeft, offsetTop);

                fragment.appendChild(tile);

                offsetTop += height;
            }

            offsetLeft += width;
        }

        return fragment;
    }
};

dmx.slideshow.transitions.default = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        duration: 400,

        setup: function() {
            this.slideshow.slider.style.setProperty('transition', 'left ' + this.options.duration + 'ms ease-out');
        },

        execute: function() {
            this.slideshow.slider.style.setProperty('left', '-' + (this.index * 100) + '%');
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
